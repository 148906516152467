import React from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ videoUrl, posterUrl }) => {
    return (
      <div style={{ position: 'relative', width: '100%', height: '400px' }}>
        <ReactPlayer
          url={videoUrl}
          controls
          width="100%"
          height="100%"
          light={posterUrl} // Definindo o poster com a URL
          playing={false} // O vídeo só vai iniciar ao clicar
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload',
                onContextMenu: (e) => e.preventDefault(),
              },
            },
          }}
        />
      </div>
    );
  };

export default VideoPlayer;
