import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Box,
  Typography,
  Paper,
} from '@mui/material';

function Register() {
  const navigate = useNavigate();

  const handleRegister = (e) => {
    e.preventDefault();
    // Simular registro e redirecionar para login
    navigate('/login');
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(https://source.unsplash.com/random/1920x1080?register)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          width: '400px',
          bgcolor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          borderRadius: 2,
        }}
      >
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Registrar
        </Typography>
        <Box
          component="form"
          display="flex"
          flexDirection="column"
          gap={2}
          onSubmit={handleRegister}
        >
          <TextField
            label="Nome"
            variant="outlined"
            fullWidth
            required
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
          />
          <TextField
            label="E-mail"
            type="email"
            variant="outlined"
            fullWidth
            required
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
          />
          <TextField
            label="Telefone"
            type="tel"
            variant="outlined"
            fullWidth
            required
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
          />
          <TextField
            label="Nova Senha"
            type="password"
            variant="outlined"
            fullWidth
            required
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
          />
          <TextField
            label="Repetir Senha"
            type="password"
            variant="outlined"
            fullWidth
            required
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            fullWidth
          >
            Cadastrar
          </Button>
        </Box>
        <Button
          variant="text"
          color="inherit"
          fullWidth
          sx={{ mt: 2 }}
          onClick={() => navigate('/login')}
        >
          Já tem uma conta? Faça login
        </Button>
      </Paper>
    </Box>
  );
}

export default Register;
