import React, { useEffect, useState } from 'react';
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Grid,
    Card,
    CardContent,
    Box,
    Pagination,
    TextField,
    Button,
    Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import axios from 'axios';

const urlBackend = 'https://dramas.shop';

const Dashboard = () => {
    const [sales, setSales] = useState([]);
    const [total, setTotal] = useState(0);
    const [balance, setBalance] = useState(0); // Saldo atual
    const [expenses, setExpenses] = useState(0); // Custos
    const [page, setPage] = useState(1);
    const [limit] = useState(10); // Quantidade por página
    const [startDate, setStartDate] = useState(dayjs().startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(dayjs().endOf('month').format('YYYY-MM-DD'));

    // Busca vendas
    const fetchSales = async () => {
        try {
            const offset = (page - 1) * limit;

            const response = await axios.get(`sales`, {
                params: {
                    startDate,
                    endDate,
                    offset,
                    limit,
                },
            });

            setSales(response.data.sales);
            setTotal(response.data.total);
        } catch (error) {
            console.error('Erro ao buscar vendas:', error);
        }
    };

    // Busca saldo e despesas
    const fetchBalanceAndExpenses = async () => {
        try {
            const response = await axios.get(`balance`);
            setBalance(response.data.balance);
            setExpenses(response.data.expenses);
        } catch (error) {
            console.error('Erro ao buscar saldo e custos:', error);
        }
    };

    useEffect(() => {
        fetchSales();
        fetchBalanceAndExpenses();
    }, [page, startDate, endDate]);

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h4" gutterBottom>
                Dashboard de Vendas
            </Typography>

            {/* Cards de saldo, despesas e vendas */}
            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <Card elevation={3}>
                        <CardContent>
                            <Typography variant="h6">Saldo</Typography>
                            <Typography variant="h4" color="primary">
                                R$ {balance.toFixed(2)}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card elevation={3}>
                        <CardContent>
                            <Typography variant="h6">Saidas</Typography>
                            <Typography variant="h4" color="error">
                                R$ {expenses.toFixed(2)}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* Filtros de data */}
            <Grid container spacing={4} sx={{ mt: 4 }}>
                <Grid item xs={12} md={6}>
                    <TextField
                        type="date"
                        label="Data Inicial"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        type="date"
                        label="Data Final"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        onClick={() => setPage(1)} // Reinicia a página
                        fullWidth
                    >
                        Filtrar
                    </Button>
                </Grid>
            </Grid>

            {/* Tabela de Vendas */}
            <Paper sx={{ mt: 4 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Cliente</TableCell>
                            <TableCell align="center">Valor Pago</TableCell>
                            <TableCell align="center">Data</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sales.map((sale) => (
                            <TableRow key={sale.id}>
                                <TableCell>{sale.userId}</TableCell>
                                <TableCell align="center">R$ {sale.amount}</TableCell>
                                <TableCell align="center">
                                    {dayjs(sale.approvedAt).format('DD/MM/YYYY HH:mm')}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>

            {/* Paginação */}
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                <Pagination
                    count={Math.ceil(total / limit)}
                    page={page}
                    onChange={(e, value) => setPage(value)}
                />
            </Box>
        </Container>
    );
};

export default Dashboard;
