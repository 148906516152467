import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import {
  TextField,
  Button,
  Box,
  Typography,
  Link as MuiLink,
  Paper,
} from '@mui/material';

function Login() {
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = () => {
    const fakeToken = '123456789abcdef'; // Token fictício
    login(fakeToken); // Salva o token no contexto e no localStorage
    navigate('/'); // Redireciona após login
  };

  const handleRegister = () => {
    navigate('/register'); // Redireciona para a página de registro
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(https://source.unsplash.com/random/1920x1080?movie)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          width: '400px',
          bgcolor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          borderRadius: 2,
        }}
      >
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Entrar
        </Typography>
        <Box
          component="form"
          display="flex"
          flexDirection="column"
          gap={2}
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}
        >
          <TextField
            label="Usuário"
            variant="outlined"
            fullWidth
            required
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
          />
          <TextField
            label="Senha"
            type="password"
            variant="outlined"
            fullWidth
            required
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            fullWidth
          >
            Entrar
          </Button>
        </Box>
        <Box
          mt={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={1}
        >
          <Typography variant="body2">Ainda não tem uma conta?</Typography>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            fullWidth
            onClick={handleRegister}
          >
            Registrar-se
          </Button>
        </Box>
        <MuiLink
          href="#"
          variant="body2"
          color="inherit"
          underline="hover"
          display="block"
          textAlign="center"
          mt={2}
        >
          
        </MuiLink>
      </Paper>
    </Box>
  );
}

export default Login;
