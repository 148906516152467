import React from 'react';
import { Button, Fab } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const WhatsAppButton = ({ phoneNumber, message, type = 'icon' }) => {

    const handleWhatsAppClick = () => {
        const encodedMessage = encodeURIComponent(message); // Codifica a mensagem para a URL
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappUrl, '_blank'); // Abre o WhatsApp em uma nova aba
    };

    return type === 'icon' ? (
        <Fab
            color="success"
            aria-label="whatsapp"
            onClick={handleWhatsAppClick}
            sx={{
                position: 'fixed',
                bottom: 16,
                right: 16,
                backgroundColor: '#25D366',
                color: '#fff',
                '&:hover': {
                    backgroundColor: '#1DA851',
                },
            }}
        >
            <WhatsAppIcon fontSize='large' />
        </Fab>
    ) :  <Button
    variant="contained"
    onClick={handleWhatsAppClick}
    startIcon={<WhatsAppIcon fontSize="large" />}
    sx={{
        backgroundColor: '#25D366',
        color: '#fff',
        width: '80%',
        height: '60px',
        padding: '0 24px',
        fontWeight: 'bold',
        textTransform: 'none',
        borderRadius: '30px', // Bordas arredondadas
        boxShadow: 6, // Maior elevação
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute', // Centraliza o botão
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)', // Centraliza na tela
        '&:hover': {
            backgroundColor: '#1DA851',
            boxShadow: 8, // Elevação maior no hover
        },
    }}
>
    Peça um filme ou série!
</Button>
;
};

export default WhatsAppButton;
