import React from 'react';
import { Card, CardMedia, Button } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { usePageConfig } from '../App';


// Estilizando o Card
const CustomCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  height: 400,
  margin: theme.spacing(2),
  borderRadius: '16px',
  overflow: 'hidden',
  boxShadow: '0 6px 20px rgba(0,0,0,0.7)',
  position: 'relative', // Necessário para posicionar o botão
  transition: '0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 12px 30px rgba(0,0,0,0.7)',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(2),
  left: '50%',
  transform: 'translateX(-50%)',
  width: '95%',
  backgroundColor: 'rgba(0, 0, 0, 0.8)', // Fundo translúcido
  backdropFilter: 'blur(4px)', // Opcional: aplica um leve desfoque no fundo
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)'

  },
  padding: '10px 20px',
  borderRadius: '20px',
  fontWeight: 'bold',
  zIndex: 2, // Garantir que o botão fique acima da imagem
}));

const MovieCard = ({ movie }) => {
  const navigate = useNavigate();
  const pageConfig  = usePageConfig();
  const urlBackend = pageConfig.urlBackend
  return (
    <CustomCard
      onClick={() => navigate(`/${movie.id}`)}>
      <CardMedia
        component="img"
        image={`${urlBackend}/images/${movie.image}`}
        alt={movie.name}
        sx={{
          height: '100%',
          width: '100%',
          objectFit: 'cover',
        }}
      />
      <StyledButton
        size="large"
        onClick={() => navigate(`/${movie.id}`)}
      >
        🍿 Assistir {movie.name.replace(/\.[^/.]+$/, "")}
      </StyledButton>
    </CustomCard>
  );
};

export default MovieCard;
