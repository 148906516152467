import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Link as MuiLink,
    CircularProgress,
    Box,
} from '@mui/material';

import axios from 'axios';

function AuthMovie() {
    const navigate = useNavigate();
    const { token } = useParams();
    const [userId, setUserId] = useState('');

    useEffect(() => {

        const validateToken = async () => {
            let existingUserId = localStorage.getItem('userId');
            if (existingUserId) {
                setUserId(existingUserId);
            } else {
                const newUserId = `user_${Date.now()}`;
                existingUserId = newUserId;
                localStorage.setItem('userId', newUserId);
                setUserId(newUserId);
            }
    
            try {
                const response = await axios.post('token-validator', { userId: existingUserId, token });
                console.log(response.data);
                navigate(`/${response.data.number}`);
            } catch (err) {
                console.error("Erro ao validar token: ", err);
                navigate(`/`);
            }
        };
    
        validateToken();

    }, [])



    const handleRegister = () => {
        navigate('/register'); // Redireciona para a página de registro
    };

    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(https://source.unsplash.com/random/1920x1080?movie)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                color: '#fff',
            }}
        >
            <CircularProgress color="secondary" size={80} />
        </Box>
    );
}

export default AuthMovie;
